// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kupivip-js": () => import("./../src/pages/kupivip.js" /* webpackChunkName: "component---src-pages-kupivip-js" */),
  "component---src-pages-murphy-js": () => import("./../src/pages/murphy.js" /* webpackChunkName: "component---src-pages-murphy-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-simplify-js": () => import("./../src/pages/simplify.js" /* webpackChunkName: "component---src-pages-simplify-js" */),
  "component---src-pages-specle-js": () => import("./../src/pages/specle.js" /* webpackChunkName: "component---src-pages-specle-js" */)
}

