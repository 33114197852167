export default {
  // useColorSchemeMediaQuery: true,
  initialColorMode: 'light',
  colors: {
    text: "#22201e",
    vibrantText: '#22201e',
    background: "#fff",
    backgroundTranslucent: "rgba(255,255,255,.8)",
    subtleBg: '#f1f2f3',
    primary: "blue",
    secondary: "#e5e5e5",
    highlight: 'rgba(0,21,255,.99)',
    muted: '#86868b',
    hover1: '#512abf',
    hover2: '#9e267d',
    hover3: '#1b54a9',
    hover4: '#277115',
    hover5: '#e49053',
    modes: {
      dark: {
        text: 'rgba(255,255,255,.6)',
        vibrantText: 'rgba(255,255,255,.95)',
        background: '#0e0e0e',
        backgroundTranslucent: 'rgba(45,45,47,.8)',
        subtleBg: '#1c1c1c',
        primary: '#ee7855',
        secondary: "#2b2c2c",
        highlight: 'rgba(232,148,255,.99)',
        muted: 'rgba(255, 255, 255, 0.6)',
      }
    }
  },
  fonts: {
    body: "Aeroport, system-ui, sans-serif",
    heading: "Aeroport, system-ui, sans-serif",
    monospace: '"Jet Brains Mono", monospace',
  },
  fontWeights: {
    body: 400,
    medium: 500,
    heading: 400,
  },
  lineHeights: {
    body: 1.4,
    heading: 1.19,
  },
  fontSizes: [
    'calc(.2vw + 15.36px)', // body
    'calc(.478vw + 14.4704px)', // medium
    'calc(.57361vw + 16.16444px)',// h3
    'calc(1.025vw + 18.72px)', // H2
    'calc(1.33843vw + 19.71702px)' // H1
  ],
  space: [0, '.25rem', '.5rem', '.75rem', '1rem', '1.25rem', '1.5rem', '2rem', '3rem', '3.5rem', '4rem', '4.5rem', '5.5rem', '8rem', '9rem', 2560, 5120],
  breakpoints: ['44em', '64em', '80em'],
  zIndices: [0, 50, 100],
  radii: [0, 4, 8, 12, 16, 24],
  shadows: ['0 12px 12px -6px rgba(0,0,0,.2), 0 0 0 1px rgba(0,0,0,.1)', '0 0.1px 0.2px rgba(0, 0, 0, 0.011), 0 0.3px 0.4px rgba(0, 0, 0, 0.016), 0 0.5px 0.8px rgba(0, 0, 0, 0.02), 0 0.9px 1.3px rgba(0, 0, 0, 0.024), 0 1.7px 2.5px rgba(0, 0, 0, 0.029), 0 4px 6px rgba(0, 0, 0, 0.04)'],
  sizes: {
    container: 'unset'
  },
  borders: ['2px solid'],
  styles: {
    root: {
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      letterSpacing: '-.02em',
      fontSize: 0,
    },
    hr: {
      color: 'secondary',
      m: 2,
    },
    a: {
      color: 'vibrantText',
      textDecoration: 'none',
      position: 'relative',
      outline: 'none',
      '&:after': {
        content: '""',
        position: 'absolute',
        display: 'inline-block',
        bg: 'muted',
        left: '50%',
        height: '3px',
        bottom: 0,
        borderRadius: 2,
        opacity: .2,
        transform: 'translateY(2px) translateX(-50%)',
        transition: "background .1s ease-in",
        width: '100%',
      },
      "&:hover:after, &:active:after": {
        bg: "transparent"
      }
    },
    strong: {
      color: 'vibrantText',
    },
    ol: {
      pl: 7,
      'li': {
        listStyle: 'none',
        '&:nth-of-type(1):before': {content: '"①"'},
        '&:nth-of-type(2):before': {content: '"②"'},
        '&:nth-of-type(3):before': {content: '"③"'},
        '&:nth-of-type(4):before': {content: '"④"'},
        '&:nth-of-type(5):before': {content: '"⑤"'},
        '&:nth-of-type(6):before': {content: '"⑥"'},
        '&:nth-of-type(7):before': {content: '"⑦"'},
        '&:nth-of-type(8):before': {content: '"⑧"'},
        '&:nth-of-type(9):before': {content: '"⑨"'},
      }
    },
    ul: {
      listStylePosition: 'outside',
      listStyleImage: 'none',
      pl: 7,
      mt: '1em',
      mb: '1.5em',
      '&:last-of-type': {
        mb: 0
      }
    },
    li: {
      listStyle: 'none',
      '&:before': {
        display: 'inline-block',
        content: '"○"',
        ml: -7,
        color: 'muted',
        width: '2rem',
      },
      '& + &': {
        mt: '.5em',
      }
    },
    p: {
      m: 0,
      hyphens: ['auto', null, 'unset'],
      hangingPunctuation: 'first allow-end last',
      '& + &': {
        mt: '1em',
      }
    },
  },
  grids: {
    normal: {
      gridGap: 5,
      gridColumnGap: 5,
      gridTemplateColumns: ['repeat(4, 4fr)', 'repeat(12, 12fr)', null, 'repeat(16, 16fr)']
    },
  },
  layout: {
    container: {
      px: [4, 6, 8],
    },
    header: {
      px: [2, 3, 4],
      py: [3, null, 4],
      alignItems: 'center',
      justifyContent: 'space-between',
      '*': {
        pointerEvents: 'auto'
      }
    }
  },
  text: {
    default: {
      fontSize: 0,
      mb: '1.5em',
      '&:last-child': {
        mb: 0
      }
      // '& + &': {
      //   textIndent: '2em'
      // }
    },
    h1: {
      color: 'vibrantText',
      fontSize: 4,
      fontWeight: 'body',
      lineHeight: 'heading',
    },
    h2: {
      color: 'vibrantText',
      fontSize: 3,
      fontWeight: 'body',
      lineHeight: 'heading',
    },
    h3: {
      color: 'vibrantText',
      fontSize: 2,
      fontWeight: 'body',
    },
    h4: {
      color: 'vibrantText',
      fontSize: 1,
      fontWeight: 'body',
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.2em',
    },
    heading: {
      fontSize: 2,
      maxWidth: '60rem'
    },
    caption: {
      fontFamily: 'monospace',
      fontSize: '11px',
    },
  },
  boxes: {
    base: {
      bg: 'subtleBg',
      width: 'auto',
      gridColumn: '1/-1',
      overflow: 'hidden',
      borderRadius: 3,
    },
    gaplessImage: {
      variant: 'boxes.base',
      // a workaround for Safari which doesn't hide content behind rounded corners
      WebkitMaskImage: '-webkit-radial-gradient(white, black)'
    },
    image: {
      variant: 'boxes.base',
      p: [4, 7, 9, 10],
    },
    imageHalf: {
      variant: 'boxes.base',
      gridColumn: ['1/-1', 'span 6', null, 'span 8' ],
      p: [4, 7, 9, 10],
    },
    imageHalfGapless: {
      variant: 'boxes.base',
      gridColumn: ['1/-1', 'span 6', null, 'span 8' ],
      mr: [-4, '-10px', 0],
      py: [4, 7, 9, 10],
      '&.right': {
        ml: [-4, '-10px', 0],
      }
    },
    imageOneThird: {
      variant: 'boxes.base',
      gridColumn: ['1/-1', 'span 4', null, 'span 6' ],
      ml: [-4, -7, -9, -10],
      mr: [-4, '-10px', 0],
      p: [4, 7, 9, 10],
      '&.right': {
        ml: [-4, '-10px', 0],
        mr: [-4, -7, -9, -10],
      },
      '.gatsby-image-wrapper': {
        // maxHeight: '500px',
      }
    },
    imageTwoThirds: {
      variant: 'boxes.base',
      gridColumn: ['1/-1', 'span 8', null, 'span 10' ],
      ml: [-4, -7, -9, -10],
      mr: [-4, '-10px', 0],
      p: [4, 7, 9, 10],
      '&.right': {
        ml: [-4, '-10px', 0],
        mr: [-4, -7, -9, -10],
      },
      '.gatsby-image-wrapper': {
        // maxHeight: '500px',
      }
    },
    slide: {
      bg: "subtleBg",
      borderRadius: 3,
      p: [2, 4, 5, 7]
    },

    slideL: {
      bg: "subtleBg",
      borderRadius: 3,
      p: [5, 7]
    },

    slideWrapper: {
      pl: [4, 6, 8],
      outline: 'none'
    },

  },
  buttons: {
    toggle: {
      color: 'text',
      border: 'none',
      padding: 0,
      font: "inherit",
      background: 'none',
      appearance: 'none',
      letterSpacing: "inherit",
      cursor: 'pointer',
      '&:hover': {opacity: 0.3},
      '&:active': {
        color: 'inherit'
      },
    }
  },
  links: {
    nav: {
      color: 'text',
      textDecoration: 'none',
      bg: 'backgroundTranslucent',
      // Broken in the latest Safari
      // backdropFilter: 'blur(10px) saturate(4.8)',
      boxShadow: '0 0.1px 0.2px .5px rgba(0, 0, 0, 0.05), 0 0.1px 0.2px rgba(0, 0, 0, 0.011), 0 0.3px 0.4px rgba(0, 0, 0, 0.016), 0 0.5px 0.8px rgba(0, 0, 0, 0.02), 0 0.9px 1.3px rgba(0, 0, 0, 0.024), 0 1.7px 2.5px rgba(0, 0, 0, 0.029), 0 4px 6px rgba(0, 0, 0, 0.04)',
      lineHeight: '1',
      borderRadius: '40px',
      pt: '12px',
      pb: '14px',
      px: 5,
      outline: 'none',
      cursor: 'pointer',
      transition: '.1s all ease-in',
      "&:after": {
        content: "unset"
      },
      '&:hover, &:focus, &:active, &[aria-current]': {
        color: 'background',
        bg: 'vibrantText',
      },
    },
    about: {
      color: 'text',
      '&:not(last-of-type)': {
        mb: 1,
      },
    },
    button: {
      variant: 'links.nav',
      display: 'flex',
      alignItems: 'center',
      px: '14px',
      'svg': {
        fill: "currentColor"
      },
      'svg + span': {
        ml: 2,
      }
    }
  }
}