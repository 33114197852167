module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-5974836-2"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"defaultLayouts":{"default":"/vercel/path0/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-transformer-remark","options":{"plugins":["gatsby-remark-unwrap-images"]}},"gatsby-remark-unwrap-images",{"resolve":"gatsby-remark-images","options":{"quality":100,"disableBgImage":true,"maxWidth":2040,"backgroundColor":"transparent","withWebp":true,"disableBgImageOnAlpha":true,"linkImagesToOriginal":false}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Maxim Melnikov","short_name":"Maxim Melnikov","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"minimal-ui","icon":"content/assets/avatar.jpg"},
    },{
      plugin: require('../node_modules/gatsby-plugin-vercel/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
